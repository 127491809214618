<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff" class="vps-detail__inner">
        <div class="vps-detail__content">
          <div>
            <layout-stack-title
              :title="tariff.name"
              :specs="containerSize"
              :icon="isPublic ? 'public' : 'private'"
              type="storage"
              class="vps-detail__head"
            >
            </layout-stack-title>
            <tabs :list="navStorage" class="medium-title">
              <template #item="{ item }">
                <router-link exact-active-class="active" :to="item.to">
                  {{ item.title }}
                </router-link>
              </template>
            </tabs>
          </div>
          <transition name="slide-fade">
            <router-view v-if="tariff" :tariff="tariff"></router-view>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import showErrorModal from '@/mixins/showErrorModal';
import BalancerContextMenu from '../pages/Main/components/BalancerContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import RuleHeaders from '@/layouts/Stack/pages/Main/components/RuleHeaders';
import VueScrollTo from 'vue-scrollto';
import tabs from '@/components/Tabs/Tabs';
import PageBlockStack from '@/components/PageBlock/PageBlockStack';
import update from '@/layouts/Stack/mixins/update';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import { format } from 'date-fns';
Vue.use(VueScrollTo);
export default {
  name: 'Storage',
  components: {
    LayoutStackTitle,
    // SidebarButton,
    // PageBlockStack,
    // BalancerContextMenu,
    BaseAlert,
    tabs,
  },
  mixins: [/*storeMixin,*/ providerChange, showErrorModal /*, update*/],
  props: {
    storageid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newRules: [],
      isCurrentLoading: false,
      isServerError: false,
      navStorage: [
        {
          title: this.$t('navStorage.object'),
          to: { name: 'containerObjects' },
        },
        {
          title: this.$t('navStorage.config'),
          to: { name: 'ObjectsView' },
        },
      ],
      tabs: [
        {
          title: this.$t('tab.servers'),
          component: 'RuleServersCreate',
          show: true,
        },
        {
          title: this.$t('tab.algorithm'),
          component: 'RuleAlgorithm',
          show: true,
        },
        {
          title: this.$t('tab.check'),
          component: 'RuleCheck',
          show: true,
        },
        {
          title: this.$t('tab.connect'),
          component: 'RuleConnect',
          show: true,
        },
        {
          title: this.$t('tab.headers'),
          component: 'RuleHeaders',
          show: false,
        },
      ],
    };
  },
  computed: {
    tariff() {
      return this.$store.state.moduleStack.storages.find(x => x.name === this.storageid);
    },
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    isPublic() {
      return this.tariff['x-container-read'] === '.r:*,.rlistings';
    },
    containerSize() {
      let list = [];
      if (this.tariff.bytes) {
        list.push(objectSizeParser(this.tariff.bytes));
      } else {
        list.push(objectSizeParser(0));
      }
      if (this.tariff.count) {
        list.push(this.$tc('object', this.tariff.count));
      } else {
        list.push(this.$tc('object', 0));
      }
      if (this.tariff.date) list.push(format(this.tariff.date, 'dd.MM.yyyy, HH:mm'));
      // $tc('object', item.members.length)
      // if (this.currentServer.cpu) list.push(this.currentServer.cpu.toString());
      // if (this.currentServer.ram) list.push(this.currentServer.ram.toString());
      return {
        head: '',
        list,
      };
    },
    // },
    // containerSize() {
    //   return { size: objectSizeParser(this.tariff.bytes) };
    // },
    // specs() {
    //   return objectSizeParser(item.bytes)
    // },

    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
    tariff: {
      handler: function (event) {
        if (!event) this.getCurrentStorage(this.storageid);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.isCurrentLoading = true;
    this.$store
      .dispatch('moduleStack/fetchOpenStackRequest', 'projects')
      .then(async data => {
        console.log(data);
        if (!this.$store.state.moduleStack.storages.find(x => x.name === this.storageid))
          await this.$store.dispatch('moduleStack/fetchProjectStorages', this.project);
      })
      .then(async data => {
        console.log(data);
        await this.getCurrentStorage(this.storageid);
      })
      .finally(() => (this.isCurrentLoading = false));
    this.$nextTick(() => this.setIsMobile());
    // if (this.project) this.getCurrentStorage(this.storageid);
    // console.log(localStorage.getItem('addRule'));
  },
  beforeDestroy() {},
  methods: {
    async getCurrentStorage(name) {
      const paramsHead = {
        project: this.project,
        name: name,
        type: 'head',
      };
      const paramsGet = {
        project: this.project,
        name: name,
        type: 'get',
      };

      return new Promise(async resolve => {
        await this.$store.dispatch('moduleStack/fetchStorages', paramsGet).then(async data => {
          // console.log(data);
          await this.$store.dispatch('moduleStack/fetchStorages', paramsHead);
          resolve(data);
        });
      });
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    specs() {
      return objectSizeParser(this.tariff.bytes);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addFolder": "Добавить папку",
      "object": " 0 объектов | 1 объект | {n} объекта | {n} объектов",
      "port": "Порт",
      "navStorage": {
        "object": "Объекты",
        "config": "Конфигурация"
      },
      "protocol": "Протокол",
      "tableHead": {
        "name": "Правило",
        "type": "Тип",
        "network": "Сеть",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      },
      "nav": {
        "servers": "Серверы",
        "connect": "Соединения",
        "check": "Проверка",
        "headers": "Заголовки",
        "algorithm": "Алгоритм"
      },
      "rule": {
        "title": "Новое правило",
        "hint": "Подсказка",
        "deschint": "Описание",
        "description": "Описание",
        "name": "Правило"
      },
      "tab": {
        "servers": "Серверы",
        "connect": "Соединения",
        "check": "Проверка",
        "headers": "Заголовки",
        "algorithm": "Алгоритм"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {

  //background: #fff;
  //border-color: #dee2e6 #dee2e6 #0b5ed7 #dee2e6;
  //color: #0b5ed7;
}
//.p-tabview-selected{
  //background: #0b5ed7;
  //border-bottom-color: #0b5ed7;
//}
//.p-tabview-nav-link {
  //background: #0b5ed7;
  //border-bottom-color: #0b5ed7;
//}
//  .p-highlight {

  //border-bottom-color: #0b5ed7;
  //}
.base-input__field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: flex-start;
}
  .rule {
    &__form {
      +breakpoint(sm-and-up) {
        flexy(flex-start, flex-end, wrap);
      }
      +breakpoint(md-and-up) {
        flex-wrap: nowrap;
        padding-bottom: 1rem;
      }
    }

    &__field {
      +breakpoint(sm-and-up) {
        flex: 1 1 auto;
      }

      & + & {
        margin-top: 0.25rem;

        +breakpoint(sm-and-up) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }
    }
    &__card {
      margin-bottom: 1rem;
    }
  }
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    //display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
